import {
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  setPersistence,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import { auth } from "./firebase";

const _AuthProvider = {
  isAuthenticated: false,
  user: null,
  async signIn(email, password, callback) {
    await setPersistence(auth, browserSessionPersistence)
      .then(async () => {
        await signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            // Signed in
            _AuthProvider.isAuthenticated = true;
            _AuthProvider.user = userCredential.user;
            callback(userCredential.user);
          })
          .catch((error) => {
            _AuthProvider.isAuthenticated = false;
            console.log("Login error", error);
          });
      })
      .catch((error) => {
        _AuthProvider.isAuthenticated = false;
        console.log("setPersistance error", error);
      });
  },
  async signOut(callback) {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        _AuthProvider.isAuthenticated = false;
        _AuthProvider.user = null;
        setTimeout(callback, 100);
      })
      .catch((error) => {
        console.log("Logout error", error);
      });
  },
  async signUp(email, password, callback) {
    await createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        callback(user);
      })
      .catch((error) => {
        console.log("signUp.error", error);
      });
  },
  async resetPassword(email, callback) {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        _AuthProvider.isAuthenticated = false;
        _AuthProvider.user = null;
        callback();
      })
      .catch((error) => {
        console.log("sendPasswordResetEmail error", error);
      });
  },
};

export { _AuthProvider };
