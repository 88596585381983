/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState, useRef } from "react";
import { db } from "../utils/firebase";
import { doc, setDoc, deleteDoc } from "firebase/firestore";
import { collection, onSnapshot } from "firebase/firestore";
import { Generator } from "firebase-auto-ids/src";
import {
  EditorState,
  ContentState,
  Modifier,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import ReactMarkdown from "react-markdown";
import DatePicker from "react-datepicker";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { stateFromHTML } from "draft-js-import-html";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import _ from "lodash";

import SanityMobilePreview from "sanity-mobile-preview";

import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CreateIcon from "@mui/icons-material/Create";
import SaveIcon from "@mui/icons-material/Save";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteIcon from "@mui/icons-material/Delete";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import SendIcon from "@mui/icons-material/Send";
import MenuIcon from "@mui/icons-material/Menu";

import {
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { ArchiveOutlined, Group, NewspaperOutlined } from "@mui/icons-material";

import "../components/TextEditor.css";
import "./EditorPage.css";
import "./Draft.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "sanity-mobile-preview/dist/index.css?raw";

function EditorPage({ ...props }) {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  // Story handling
  const [currentStage, setCurrentStage] = useState("production"); // production || staging
  const [stories, setStories] = useState([]);
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [uri, setURI] = useState("");
  const [publishDate, setPublishDate] = useState(new Date());
  const [showDate, setShowDate] = useState(false);
  const [type, setCardType] = useState("STORY");
  const [tags, setTags] = useState([]);
  const [blocks, setBlocks] = useState([]);
  // UI stuff
  const [snack, setSnack] = useState("");
  const [preview, setPreview] = useState("EMAIL");
  const editorRef = useRef(null);

  useEffect(() => {
    const _collection = currentStage === "production" ? "stories" : "staging";
    onSnapshot(collection(db, _collection), (snapshot) => {
      setStories(
        _.orderBy(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })),
          ["index"],
          ["asc"]
        )
      );
    });
  }, [currentStage]);

  useEffect(() => {
    const contentState = editorState?.getCurrentContent?.();
    const contentBlocks = contentState?.getBlocksAsArray?.();
    setBlocks(contentBlocks);
  }, [editorState]);

  const handleNew = async () => {
    // reset id
    setId("");
    // Reset draft editor state
    const _editorState = EditorState.push(
      editorState,
      ContentState.createFromText("")
    );
    setEditorState(_editorState);
    setTitle("");
    setSubtitle("");
    setURI("");
    setPublishDate(new Date());
    setCardType("STORY");
    setTags([]);
    setShowDate(false);
  };
  const handleSelect = async (_id) => {
    const selected = stories.find((x) => x.id === _id);
    if (!selected) {
      alert("Invalid story, please refresh");
      return;
    }
    // Set id
    setId(_id);
    // Reset draft editor state
    const rawData = markdownToDraft(selected.text);
    const contentState = convertFromRaw(rawData);
    const _editorState = EditorState.createWithContent(contentState);
    setEditorState(_editorState);
    setTitle(selected?.title ?? "");
    setSubtitle(selected?.subtitle ?? "");
    setCardType(selected?.type ?? "STORY");
    setURI(selected?.uri ?? "");
    setPublishDate(selected?.date ? new Date(selected?.date) : new Date());
    setTags(selected?.tags ?? []);
  };
  const handleSave = async (stage) => {
    if (!blocks) {
      console.log("No blocks");
      return;
    }
    if (!stage) {
      console.log("No stage", stage);
      return;
    }
    const _id = id.length ? id : new Generator().generate(Date.now());
    const rawObject = convertToRaw(editorState.getCurrentContent());
    const text = draftToMarkdown(rawObject);
    const _collection = stage === "production" ? "stories" : "staging";
    const _doc = doc(db, _collection, _id);
    let index;
    if (currentStage !== stage) {
      // Publishing
      onSnapshot(collection(db, _collection), async (snapshot) => {
        index = snapshot.size; // will return the collection size
        await setDoc(_doc, {
          title,
          text,
          type,
          date: publishDate.toISOString() ?? new Date().toISOString(),
          index: index >= 0 ? index : stories.length,
          ...(uri && { uri }),
          ...(subtitle && { subtitle }),
          ...(tags.length && { tags }),
          ...(showDate && { showDate }),
        });
        setId(_id);
        setSnack("SAVED");
      });
    } else {
      stories.findIndex((x) => x.id === _id);
      await setDoc(_doc, {
        title,
        text,
        type,
        date: publishDate.toISOString() ?? new Date().toISOString(),
        index: index >= 0 ? index : stories.length,
        ...(uri && { uri }),
        ...(subtitle && { subtitle }),
        ...(tags.length && { tags }),
        ...(showDate && { showDate }),
      });
      setId(_id);
      setSnack("SAVED");
    }
  };
  const handleArchive = async () => {
    if (!id || !title || !blocks.length) {
      return;
    }
    const selected = stories.find((x) => x.id === id);
    if (!selected) {
      alert("Invalid story, please refresh");
      return;
    }
    // Store doc in archive collection
    const _doc = doc(db, "archive", id);
    await setDoc(_doc, {
      ...selected,
    });
    // Delete doc from stories collection
    await deleteDoc(doc(db, "stories", id));
    // Then reset
    handleNew();
    setSnack("ARCHIVED");
  };
  const handleDelete = async () => {
    // Delete
    const _collection = currentStage === "production" ? "stories" : "staging";
    await deleteDoc(doc(db, _collection, id));
    // Then reset
    handleNew();
    setSnack("DELETED");
  };
  const handlePastedText = (text, html) => {
    const blockMap = stateFromHTML(html).blockMap;
    const newState = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      blockMap
    );
    const _editorState = EditorState.push(
      editorState,
      newState,
      "insert-fragment"
    );
    setEditorState(_editorState);
    return true;
  };
  const handleDrop = async (item) => {
    // Ignore drop outside droppable container
    if (!item.destination) {
      return;
    }
    var updatedList = [...stories];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(item.index, 1);
    // Add dropped item
    updatedList.splice(item.destination.index, 0, reorderedItem);
    // Update State
    setStories(updatedList);
    // update db docs
    await Promise.all(
      updatedList.map(async (item, i) => {
        // const _doc = doc(db, "stories", item.id);
        // await setDoc(_doc, {
        //   ...item,
        //   index: i,
        // });
      })
    );
  };

  const handleTitle = (evt) => setTitle(evt.target.value);
  const handleSubtitle = (evt) => setSubtitle(evt.target.value);
  const handleURI = (evt) => setURI(evt.target.value);
  const handleTags = (evt) => setTags(evt.target.value?.split(","));
  const handleSnackClose = () => setSnack("");
  const handleCardType = (evt) => setCardType(evt.target.value);

  return (
    <div className="App">
      <div className="Wrapper">
        <div className="Sidebar">
          <Divider />
          <List>
            <ListItem disablePadding onClick={() => setSnack("TODO")}>
              <ListItemButton>
                <ListItemIcon>
                  <NewspaperOutlined color="primary" />
                </ListItemIcon>
                <ListItemText primary="Bytes" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding onClick={() => setSnack("TODO")}>
              <ListItemButton>
                <ListItemIcon>
                  <ArchiveOutlined color="primary" />
                </ListItemIcon>
                <ListItemText primary="Archived" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding onClick={() => setSnack("TODO")}>
              <ListItemButton>
                <ListItemIcon>
                  <Group color="primary" />
                </ListItemIcon>
                <ListItemText primary="Users" />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
        </div>
        <div className="Container">
          <div className="EditorWrapper">
            {/* Editor */}
            <Paper className="Column" elevation={3}>
              <div className="Editor">
                <div className="Controls">
                  <div className="File-controls">
                    <div
                      style={{
                        display: "flex",
                        gap: "0.5rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="small"
                        onClick={handleNew}
                        endIcon={<CreateIcon />}
                      >
                        New
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => handleSave(currentStage)}
                        endIcon={<SaveIcon />}
                        disabled={!title.length}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        color="success"
                        onClick={() => handleSave("production")}
                        endIcon={<SendIcon />}
                        disabled={
                          !title.length || currentStage === "production"
                        }
                      >
                        Publish
                      </Button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "0.5rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="small"
                        color="info"
                        onClick={() => handleArchive(id)}
                        endIcon={<ArchiveIcon />}
                        disabled={!stories.find((x) => x.id === id)}
                      >
                        Archive
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        color="error"
                        onClick={() => handleDelete(id)}
                        endIcon={<DeleteIcon />}
                        disabled={!stories.find((x) => x.id === id)}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                  <div className="InputWrapper">
                    <input
                      value={title}
                      style={{ fontSize: "1.5rem", width: "100%" }}
                      placeholder="Title"
                      onChange={handleTitle}
                    />
                    <span
                      style={{
                        color: stories.find((x) => x.id === id)
                          ? "red"
                          : "darkgrey",
                      }}
                    >
                      {currentStage === "production" &&
                      stories.find((x) => x.id === id)
                        ? "Published"
                        : "Draft"}
                    </span>
                  </div>
                  <div className="InputWrapper">
                    <TextField
                      label="Image URL"
                      value={uri}
                      size="small"
                      variant="standard"
                      placeholder="URL"
                      onChange={handleURI}
                    />
                    <TextField
                      label="Subtitle"
                      value={subtitle}
                      size="small"
                      variant="standard"
                      placeholder="subtitle"
                      onChange={handleSubtitle}
                    />
                  </div>
                  <div className="InputWrapper">
                    <TextField
                      label="Tags"
                      value={tags}
                      size="small"
                      variant="standard"
                      style={{ width: "100%" }}
                      placeholder="finance, politics, fashion..."
                      onChange={handleTags}
                    />
                    <FormControl variant="standard">
                      <InputLabel id="card-type">Card Type</InputLabel>
                      <Select
                        labelId="card-type"
                        id="card-type"
                        value={type}
                        onChange={handleCardType}
                        label="Card Type"
                      >
                        <MenuItem value={"STORY"}>Story</MenuItem>
                        <MenuItem value={"QUOTE"}>Quote</MenuItem>
                        <MenuItem value={"IMAGE"}>Image</MenuItem>
                        <MenuItem value={"FOOTER"}>Footer</MenuItem>
                        <MenuItem value={"HEADER"}>Header</MenuItem>
                      </Select>
                    </FormControl>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#AAAAAA",
                        gap: "1rem",
                      }}
                    >
                      <label
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "1rem",
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={showDate}
                          onChange={(event) =>
                            setShowDate(event.target.checked)
                          }
                        />
                      </label>
                      <DatePicker
                        selected={publishDate}
                        onChange={(date) => setPublishDate(date)}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ maxHeight: "inherit", overflowY: "scroll" }}>
                  <Editor
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                    handlePastedText={handlePastedText}
                    ref={editorRef}
                    toolbar={{
                      image: {
                        previewImage: true,
                        alt: true,
                      },
                    }}
                    {...props}
                  />
                </div>
              </div>
            </Paper>
            {/* Preview */}
            <Paper
              className="Column Paper"
              style={{
                overflowY: preview === "MOBILE" ? "inherit" : "scroll",
                ...(preview === "MOBILE" && { height: "auto" }),
              }}
              elevation={3}
            >
              <div style={{ position: "absolute" }}>
                <ButtonGroup
                  variant="contained"
                  aria-label="outlined primary button group"
                >
                  <Button
                    color={currentStage === "production" ? "error" : "success"}
                    onClick={() => {
                      const newStage =
                        currentStage === "production"
                          ? "staging"
                          : "production";
                      handleNew();
                      setCurrentStage(newStage);
                    }}
                  >
                    {currentStage === "staging" ? "Drafts" : "Live"}
                  </Button>
                  <Button
                    onClick={() => {
                      setPreview("LIST");
                      setSnack("");
                    }}
                  >
                    <MenuIcon />
                  </Button>
                  <Button
                    onClick={() => {
                      setPreview("EMAIL");
                      setSnack("");
                    }}
                  >
                    <EmailIcon />
                  </Button>
                  <Button
                    onClick={() => {
                      setPreview("MOBILE");
                      setSnack("TODO");
                    }}
                  >
                    <PhoneIphoneIcon />
                  </Button>
                  <Button
                    onClick={() => {
                      setPreview("WEB");
                      setSnack("");
                    }}
                  >
                    <PersonalVideoIcon />
                  </Button>
                </ButtonGroup>
              </div>
              {preview === "LIST" ||
              preview === "EMAIL" ||
              preview === "WEB" ? (
                <DragDropContext onDragEnd={handleDrop}>
                  <Droppable droppableId="list-container">
                    {(provided) => (
                      <div
                        className="list-container"
                        style={{ marginTop: "3rem" }}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {stories.map((story, index) => (
                          <Draggable
                            key={story.id}
                            draggableId={story.id}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                              >
                                <section
                                  onClick={() => handleSelect(story?.id)}
                                >
                                  {preview === "LIST" ? (
                                    <h3>
                                      {index + 1}. {story?.title}
                                    </h3>
                                  ) : (
                                    <>
                                      {story?.uri && (
                                        <img
                                          width={"100%"}
                                          src={story.uri}
                                          alt=""
                                        />
                                      )}
                                      {story?.subtitle && (
                                        <p style={{ whiteSpace: "pre-wrap" }}>
                                          <b>
                                            <i>{story.subtitle}</i>
                                          </b>
                                        </p>
                                      )}
                                      <h3>{story?.title}</h3>
                                      <p>
                                        <ReactMarkdown>
                                          {story?.text}
                                        </ReactMarkdown>
                                      </p>
                                    </>
                                  )}
                                </section>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <div style={{ marginTop: "3rem" }}>
                  <SanityMobilePreview>
                    <div style={{ overflowY: "scroll" }}>
                      {stories.map((story, index) => (
                        <div>
                          <section
                            onClick={() => {
                              // handleSelect(story?.id);
                            }}
                          >
                            {story?.uri && (
                              <img width={"100%"} src={story.uri} alt="" />
                            )}
                            {story?.subtitle && (
                              <p style={{ whiteSpace: "pre-wrap" }}>
                                <b>
                                  <i>{story.subtitle}</i>
                                </b>
                              </p>
                            )}
                            <h3>{story?.title}</h3>
                            <p style={{ whiteSpace: "pre-wrap" }}>
                              <ReactMarkdown>{story?.text}</ReactMarkdown>
                            </p>
                          </section>
                        </div>
                      ))}
                    </div>
                  </SanityMobilePreview>
                </div>
              )}
            </Paper>
          </div>
        </div>
        <Snackbar
          open={snack}
          autoHideDuration={6000}
          onClose={handleSnackClose}
          message={
            snack === "ARCHIVED"
              ? `Byte archived`
              : snack === "DELETED"
              ? `Byte deleted`
              : snack === "SAVED"
              ? `Byte saved`
              : snack === "STAGE"
              ? `Moved to ${
                  currentStage === "production" ? "staging" : "production"
                }`
              : snack === "TODO"
              ? `This doesn't do anything yet`
              : ""
          }
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </div>
    </div>
  );
}

export default EditorPage;
