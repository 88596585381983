/* eslint-disable jsx-a11y/alt-text */
import * as React from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
import EditorPage from "./pages/EditorPage";
import { _AuthProvider } from "./utils/auth";
import { auth as _auth } from "./utils/firebase";
import "./App.css";
import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { Lock } from "@mui/icons-material";

export default function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<LoginPage />} />
          <Route path="/forgot" element={<ForgotPasswordPage />} />
          <Route
            path="/editor"
            element={
              <RequireAuth>
                <EditorPage />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    </AuthProvider>
  );
}

function Layout() {
  return (
    <div>
      <AuthStatus />
      <Outlet />
    </div>
  );
}

let AuthContext = React.createContext();

function AuthProvider({ children }) {
  const [user, setUser] = React.useState(_AuthProvider.user || null);

  let signIn = (_email, _password, callback) => {
    return _AuthProvider.signIn(_email, _password, (user) => {
      setUser(user);
      localStorage.setItem("accessToken", _auth.currentUser.accessToken);
      callback();
    });
  };

  let signOut = (callback) => {
    return _AuthProvider.signOut(() => {
      setUser(null);
      localStorage.removeItem("accessToken");
      callback();
    });
  };

  let signUp = async (_email, _password, callback) => {
    return await _AuthProvider.signUp(_email, _password, () => {
      callback();
    });
  };

  let forgotPassword = async (_email, callback) => {
    _AuthProvider.resetPassword(_email, () => {
      callback();
    });
  };

  let value = { user, signIn, signOut, signUp, forgotPassword };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  return React.useContext(AuthContext);
}

function AuthStatus() {
  const auth = useAuth();
  const navigate = useNavigate();
  const user = auth.user;

  if (!user) {
    return (
      <header className="App-header">
        <img height="30rem" src={process.env.PUBLIC_URL + "/bytes.png"} />
        <p>You are not logged in.</p>
      </header>
    );
  }

  return (
    <header className="App-header">
      <img height="30rem" src={process.env.PUBLIC_URL + "/bytes.png"} />
      <p
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "2rem",
        }}
      >
        Welcome {user.email}!{" "}
        <Button
          type="submit"
          variant="contained"
          size="small"
          onClick={() => {
            auth.signOut(() => navigate("/"));
          }}
        >
          Sign Out
        </Button>
      </p>
    </header>
  );
}

function RequireAuth({ children }) {
  const auth = useAuth();
  const location = useLocation();
  const user = auth.user;

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.

    // Try login with token

    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

function LoginPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  let from = location.state?.from?.pathname || "/editor";

  function handleSubmit(event) {
    event.preventDefault();

    let formData = new FormData(event.currentTarget);
    let email = formData.get("email");
    let password = formData.get("password");

    auth.signIn(email, password, () => {
      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page, which is also really nice for the
      // user experience.
      navigate("editor", { replace: true });
    });
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <Lock />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {/* <FormControlLabel
            control={<CheckBox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/forgot" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}

function ForgotPasswordPage() {
  const navigate = useNavigate();
  const auth = useAuth();

  function handleSubmit(event) {
    event.preventDefault();

    let formData = new FormData(event.currentTarget);
    let email = formData.get("email");

    auth.forgotPassword(email, () => {
      navigate("/", { replace: true });
    });
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <Lock />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Reset Password
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

function PublicPage() {
  return <h3>Public</h3>;
}

function ProtectedPage() {
  return <h3>Protected</h3>;
}
