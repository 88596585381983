import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBLvB_IrMALzx85m0EfIt9ry6hXOhV5iWQ",
  authDomain: "bytes-d881c.firebaseapp.com",
  projectId: "bytes-d881c",
  storageBucket: "bytes-d881c.appspot.com",
  messagingSenderId: "444673278033",
  appId: "1:444673278033:web:3155ba5786595a0db57870",
  measurementId: "G-4B2NTNQ78K",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, app, auth };
